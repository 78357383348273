
@font-face {
  font-family: "iconFont";
  src:  url('../fonts/iconFont.woff2?1578098819') format('woff2'),
        url('../fonts/iconFont.woff?1578098819') format('woff');
  font-weight: normal;
  font-style: normal;
}


@mixin iconFont {

  font-family: 'iconFont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}





[class^="icon-"], [class*=" icon-"] {
  
  &:before {@include iconFont;}

}



.icon-crew:before {
  content: "\"
}
.icon-right-arrow:before {
  content: "\"
}
.icon-plus:before {
  content: "\"
}
.icon-down-chevron:before {
  content: "\"
}
.icon-error:before {
  content: "\"
}
.icon-left-chevron:before {
  content: "\"
}
.icon-right-chevron:before {
  content: "\"
}