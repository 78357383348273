.menus.mobile {
	position: fixed;
	height: 100%;
	width: 100%;
	right: 0;
	z-index: 100;
	display: block;
	background-color: $navy;
	top:0;
	transform:translateX(100%);
	transition: $transition;
	overflow-y: auto;
	z-index: 102;
	
	.top-menu {
		background-color: black;
		padding-bottom:10px;
	}
	.bottom-menu {
		padding-top:10px;
	}
	.top-menu, .bottom-menu {
		padding:20px;
	}

	.logo-nav {
		margin-top: 15px;
		.logo-wrap {
			max-width:125px;
			width:100%;
		}
	}


	.menu-toggle.close-button {
		position: relative;
		width: auto;
		margin:0;
		display: table;
		z-index:1;
		transform:translateX(15px);
		&:not(:hover) {
			.bar-1 {
				transform:rotate(45deg) translateY(-50%);
				top: 48%;
			}
			.bar-2 {
				transform:rotate(-45deg) translateY(-50%);
				bottom: 45%;
				right: 12px;
			}
		}
		.shrink & {
			transform:translateX(15px);
		}
	}

	.menu-open & {
		transform: translateX(0);
	}

	.admin-bar & {
		top: calc(32px);
	}

	nav {
		ul {
			@include list();
			li {
				width:auto;
				a {
					width:auto;
					text-decoration:none;
				}
			}
		}
		&.nav-primary {
			margin-top: 50px;	
			ul {
				li {
					&.menu-item-has-children {
						> a {
							position: relative;
							border-bottom:1px solid rgba(255,255,255,0.2);
							&:before {
								content:'';
								position: absolute;
								right: 0px;
								top: 50%;
								transform: translateY(-50%) rotate(0deg);
								@include iconFont;
								content: "\E004";
								font-size: 13px;
								transition: $transition;
							}
							&:hover {
								&:before {
									color: #fff;
								}
							}
							&.active {
								&:before {
									//margin-top: -4px;
									//transform: rotate(180deg);
									//color:$secondary;
								}
							}
						}
					}
					.sub-menu {
						max-height: 0;
						overflow: hidden;
						transition: $transition;
						li {
							a {
								font-size: 18px;
								padding-left: 30px;
							}
						}
					}
					a {
						color:#cdcdcd;
						font-size:18px;
						font-weight: 300;
						padding:10px 0px;
						transition: $transition;
						display: block;
						&:hover {
							color:#fff;
						}
					}
				}
			}
			&.nav-secondary {
				background-color: $navy;
				margin-top: 0;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		max-width: 450px;
		border-left:1px solid white;

		.logo-nav {
			margin-top: 0px;
		}

		.menu-logo {
			visibility: hidden;
		}

		.top-menu, .bottom-menu {
			padding:90px;
		}

		.top-menu {
			padding-bottom:30px;
		}
		.bottom-menu {
			padding-top:30px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
	
}
