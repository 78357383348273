.product-section-nav-container {
	
	.header-tertiary-nav & {
		@include media-breakpoint-down(lg) {
			overflow-y: hidden;
		    overflow-x: auto;
			.product-section-nav {
				display: flex;
				margin: 0 auto;
		    }
		}
	}

	.interior-banner & {
		border-top:1px solid rgba(255,255,255,0.2);
		padding-top:25px;
		padding-bottom:50px;
	}
	.product-section-nav {
		@include list();
		&.multi-links {
			margin-top:30px;
		}
		li {
			display: inline-block;
			position:relative;
			&:after {
				content: '//';
				color:#fff;
				font-size:12px;
				margin:0 15px;
				opacity: 0.6;
				color:$primary;
			}
			&:last-child {
				&:after {
					content:'';
					margin-right: 0;
				}
			}
			&.active {
				a {
					opacity: 1;
				}
			}
			a {
				font-weight: 300;
				font-size:18px;
				letter-spacing:0.5px;
				opacity: 0.6;
				text-transform: none;
				color: white;
				white-space: nowrap;
				transition: $transition;
				color:#fff;
				text-decoration: none;
				&:hover {
					opacity: 1;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}