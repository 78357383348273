.nav-page-selector {
	position:relative;
	&.has-children {
		cursor:pointer;
		&:after {
			@include iconFont();
			content:'\e004';
			position: absolute;
			right:0px;
			top:2px;
			z-index: auto;
			transition:transform 0.3s ease;
		}
		&.open {
			&:after {
				transform:rotate(180deg);
			}
		}
	}

	.nav-page-selector-list {
		@include list();
		margin-top:7px;
		display: none;
		margin-bottom:14px;
		font-size:14px;
		li {
			padding:7px 0;
			border-top:1px solid #c7d1d3;
		}
		a {
			color:$grey-med;
			&:hover {
				color:$primary;
			}
		}
	}

	.nav-selector-page-title {
		margin-top: 10px;
		font-weight: 500;
		font-size:13px;
	}

	.nav-page-selector-container {
		margin-top: 10px;
		position:relative;
		font-weight: 500;
		cursor: pointer;
		padding-right: 0px;
	    white-space: nowrap;
	    overflow: hidden;
	    max-width: 100%;
	    border-right: 16px solid #fff;
	    font-size:13px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.nav-selector-page-title {
			margin-top: 17px;
		}

		.nav-page-selector-container {
			margin-top: 17px
		}
		

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}