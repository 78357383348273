@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}


@mixin bgImg($position, $size) {
	background: {
		position: $position;
		size:$size;
		repeat:no-repeat;
	}
}

@mixin overlay($color, $percentage) {
	position: relative;
	&:before {
		content:'';
		background-color:rgba($color, $percentage);
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
	}
}

// list style mixin
@mixin list() {
  list-style-type:none;
  padding:0;
  margin:0;
}