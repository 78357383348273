.footer-content {
	.socket {
		padding: 12px 0;
		background-color: black;
		text-align: center;
		font-size: 11px;
		color:#fff;
		text-transform: uppercase;
		letter-spacing:2.5px;
		.nav {
			@include list();
			display: inline-block;
			margin:0 15px;
			li {
				display: inline-block;
				margin:0px 15px;
				margin-bottom: 0;
				a {
					text-decoration: none;
					color: #fff;
					transition:$transition;
					&:hover {
						color:$primary;
					}
				}
			}
		}
		.attribution {
			display: block;
			color: #fff;
			text-decoration: none;
			color:$grey-med;
			font-weight: 500;
			span, i {
				vertical-align: middle;
				display: inline-block;
				letter-spacing: 2.5px;
			}
			i {
			    vertical-align: -3px;
			    font-size: 14px;
			    margin-left: 10px;
			}
		}

	}
	
	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	
		.socket {
			text-align: initial;
			.nav {
				text-align: center;
			}
			.attribution {
				text-align: right;
			}
		}


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}