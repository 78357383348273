body .lity {
    background-color: rgba(0, 0, 0, .5)
}

body .lity-close,
body .lity-close:focus {
    top: 12px;
    right: 10px;
    transition: color .3s ease
}

body .lity-close:focus:hover,
body .lity-close:hover {
    color: #50bde3
}