body {
	&.home .wrap {
		background-color:$navy;
	}
	&.menu-open {
		.wrap {
			position: relative;
			&:after {
				content:'';
				background-color: rgba($navy, 0.5);
				position: absolute;
				width:100%;
				height:100%;
				left:0;
				top:0;
				z-index: 100;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.sm-mb-2 {
			margin-bottom: 20px;
		}

		.sm-mb-4 {
			margin-bottom: 40px;
		}
	}

	@include media-breakpoint-down(md) {
		.md-mb-2 {
			margin-bottom: 20px;
		}

		.md-mb-4 {
			margin-bottom: 40px;
		}
	}

	.wrap {
		background-color: $grey-light;
		.center-text {
			text-align: center;
		}

		position: relative;

		.has-grey-accent {
			position:relative;
			padding-left: 20px;
			&:before {
				content:'';
				position: absolute;
				background-color: #c7d1d3;
				top:50%;
				transform:translateY(-50%);
				left:0;
				height: calc(100% - 10px);
				width:2px;
			}
		}

		img {
			max-width: 100%;
			height: auto;
		}
		p {
			a {
				color: $secondary;
				transition: $transition;
				font-weight: 500;
				text-decoration: none;
				&:hover {
					color: $grey-med;
				}
			}
		}

		table {
			width:100%;
			border:none;
			tfoot, thead {
				th, td {
					border:none;
				}
			}
			tbody {
				tr {
					td {
						border:none;
						padding:10px;
					}
					&:nth-child(odd) {
						background-color:$grey-light - 15;
					}
				}
			}
		}


		/* =============================================================================
		Extra Small +
		========================================================================== */
		@include media-breakpoint-up(xs) {
		}

		/* =============================================================================
		Small +
		========================================================================== */
		@include media-breakpoint-up(sm) {
		}

		/* =============================================================================
		Medium +
		========================================================================== */
		@include media-breakpoint-up(md) {
		}

		/* =============================================================================
		Large +
		========================================================================== */
		@include media-breakpoint-up(lg) {
		}

		/* =============================================================================
		Extra Large +
		========================================================================== */
		@include media-breakpoint-up(xl) {
		}
	}
}
