.basic-content {
	
	h1 {
		font-weight: 100;
		
		&.centered {
			text-align: center;
			
		}
	}

	.content {
		font-weight: 400;
		&.centered {
			margin:0 auto;
			text-align: center;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		h1 {
			font-size:53px;
			line-height: 1.1em;
		}

		.content {
			&.large-text {
				font-size:20px;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}