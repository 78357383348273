.menus.desktop {
	display: inline-block;
	nav {
		display: none;
	}
	ul.nav {
		list-style-type: none;
		padding: 0;
		text-align: right;
		margin: 0;
		.shrink & {

		}
		& > li {
			display: inline-block;
			margin-right: 50px;
			
			.shrink & {
				height: 65px;
			}
			
			> a {
				padding: 0;
				text-decoration: none;
				color: white;
				text-transform: uppercase;
				transition: color 0.3s ease;
				font-size: 13px;
				font-weight: 400;
				letter-spacing:3px;
				position: relative;
				.shrink & {
					top: 50%;
				    transform: translateY(-50%);
				    display: block;
				    margin-top: -4px;
				}
				&:before {
					content:'';
					position:absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0px 0px 0px;
					border-color: transparent transparent #fff transparent;
					left:50%;
					transform:translateX(-50%);
					bottom:-24px;
					z-index: 1;
					transition:$transition;
				}
			}
			&.active {
				> a {
					color:$primary;
				}
			}
			& > a {
				&:hover {
					color:$primary;
				}
			}
			&:last-child {
				//margin-right: 0;
			}
			&.menu-item-has-children {
				&:hover:not(.menu-toggle) {
					ul {
						opacity: 1;
						visibility: visible;
						transition-delay: 0s, 0s;
						transform:translateY(0px);
					}
					a {
						&:before {
							border-width: 0 10px 10px 10px;
						}	
					}
					&:after {
						width:calc(100% - 30px);
					}
				}
				&:after {
					content:'';
					position:absolute;
					left:50%;
					transform:translateX(-50%);
					width:0;
					height: 2px;
					background-color:rgba($grey-med,1);
					transition:$transition;
					top:calc(#{$header-desktop-height} - 43px);
					.shrink & {
						display: none;
					}
				}
			}
			ul {
				width:100%;
				opacity: 0;
				visibility: hidden;
				z-index: 1;
				transition: opacity 0.2s ease 0s,visibility 0s ease .2s,transform 0.2s ease 0s;
				transform:translateY(10px);
				@include list();
				padding-top:30px;
				position: absolute;
				left: 0;
				text-align: right;
				.shrink & {
					background-color: #fff;
					padding-top:15px;
					padding-bottom: 15px;
					top: 75px;
				}
				&:before {
					.shrink & {
						content: "";
						position: absolute;
						left: 50%;
						top: 0;
						width: 9999px;
						height: 100%;
						background-color: white;
						transform: translateX(-50%);
					}
				}
				li {
					display: inline-block;
					position:relative;
					&:after {
						content: '//';
						color:#fff;
						font-size:12px;
						margin:0 15px;
						opacity: 0.6;
						.shrink & {
							color:black;
						}
					}
					&:last-child {
						&:after {
							content:'';
							margin-right: 0;
						}
					}
					&.active {
						a {
							.shrink & {
								//color:$primary;
							}
							opacity: 1;
						}
					}
					a {
						.shrink & {
							color:black;
						}
						font-size: 13px;
						font-weight: 400;
						letter-spacing: 3px;
						text-transform: uppercase;
						color: white;
						white-space: nowrap;
						transition: $transition;
						color:#fff;
						text-decoration: none;
						&:hover {
							color:$primary;
						}
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		nav {
			display: inline-block;
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}