.wow {
  visibility: hidden;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  @media (prefers-reduced-motion) {
    animation: unset !important;
    transition: none !important;
  }
}

/**
 *  Fade In Animation
 */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

/**
 *  Fade In Right
 */

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(25%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

/**
 *  Fade In Left
 */

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

/**
 *  Slide in Left
 */

@keyframes slideInLeft {
  from {
    transform: translate3d(-25%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    transform: translate3d(25%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}