.btn {
	display: inline-block;
	text-decoration: none;
	transition: color 0.3s ease;
	text-align: left;
	position: relative;
	padding: 5px 25px 5px 10px;
	outline:none;
	border:none;
	appearance:none;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
	letter-spacing:3px;
	font-size:12px;
	position:relative;
	overflow: hidden;
	&:before {
		@include iconFont;
		content: "\";
		position:absolute;
		right:6px;
		top:50%;
		transform:translateY(-50%);
		font-size:14px;
		transition:transform 0.3s ease;
	}

	&:hover {
		&:before {
			transform:translate(15%, -50%);
		}
	}

	&.blue {
		color:$primary;
	}
	&.none {
		border:none;
		background-color:none;
		padding-left:0;
		padding-right: 30px;
	}

	&.blue-arrow {
		&:before {
			color:$primary;
		}
		&.black {
			&:hover {
				&:before {
					color:white;
				}
			}
		}
	}

	&.big {
		padding: 15px 50px 15px 25px;
		font-size:14px;
		font-weight: 300;
		&:before {
			font-size:20px;
			right:15px;
		}
	}

	&.black {
		background-color: black;
		color:#fff;
		&:hover {
			background-color:$primary;
			color:#fff;
		}
	}

	&.outline {
		&.black {
			color:black;
			border: 1px solid black;
			background-color: transparent;
			&:hover {
				background-color:#000;
				color:#fff;
			}
		}
		&.white {
			color:white;
			border: 1px solid white;
			background-color: transparent;
			&:hover {
				background-color:white;
				color:black;
			}
		}
		&.grey {
			color:$grey-med;
			border: 1px solid $grey-med;
			background-color: transparent;
			&:hover {
				background-color:$primary;
				color:#fff;
				border:1px solid $primary;
			}
		}
	}

	&.yellow {
		background-color:$yellow;
		color:#000;
		&:hover {
			background-color:#fff;
		}
	}
	&.white {
		background-color:#fff;
		color:$secondary;
		&:hover {
			background-color:$primary;
			color:#fff;
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}

}


