.wrap .gform_wrapper {
	margin: 0;

	.gfield {
		&.hide-section-front-end {
			border:none;
		}
	}

	&.normalize-form_wrapper {
		input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
			height: 28px;
		}
		select {
			height: 30px;
		}
		input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
		select,
		textarea {
			background-color: #e6e8e9;
			z-index: 1;
			position: relative;
			border: none;
			border-bottom: 1px solid #c7d1d3;
			margin-top: 12px !important;
			&:focus {
				outline: none;
				border: none;
				border-bottom: 1px solid black;
				background-color: darken(#e6e8e9, 5%);
			}
		}
		label {
			display: block;
			font-weight: normal;
			font-size: 13px;
			letter-spacing: 2.2px;
			transition: $transition;
		}
	}

	&:not(.normalize-form_wrapper) {
		.top_label
			li.gfield.gf_left_half
			.ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker),
		.top_label
			li.gfield.gf_right_half
			.ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker) {
			margin-top: 0;
		}

		.gfield {
			position: relative;

			&:not(.gf_select_field) {
				label.gfield_label {
					position: absolute;
					top: 19px;
					left: 11px;
				}
			}
			&.hidden_label {
				input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
				textarea,
				select {
					padding-top: 0;
				}
			}

			&.has-input {
				label.gfield_label {
					top: 4px;
					font-size: 11px;
					opacity: 0.5;
					z-index: 2;
				}
			}
			label.gfield_label {
				display: block;
				font-weight: normal;
				font-size: 13px;
				letter-spacing: 2.2px;
				opacity: 0.8;
				transition: $transition;
			}

			input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
			select,
			textarea {
				z-index: 1;
				position: relative;
				background-color: transparent;
				border: none;
				border-bottom: 1px solid #c7d1d3;

				&:focus {
					outline: none;
					border: none;
					border-bottom: 1px solid black;
				}
			}
		}

		label.gfield_label {
			display: none;
			.gfield_required {
				color: #fff;
			}
		}

		ul li.gfield {
			margin-top: 0;
			margin-bottom: 15px;
		}
		/** 
		 *	Input, select and textarea styles
		 */

		input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
		select,
		textarea {
			background-color: transparent;
			line-height: 1em;
			border: 1px solid transparent;
			color: $pColor;
			letter-spacing: 0.9px;
			font-size: 13px;
			letter-spacing: 2.2px;
		}

		input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
		select {
			height: 52px;
			padding: 15px 10px 0px;
			transition: $transition;
			&:focus {
				outline: none;
				border-color: $primary;
			}
		}

		textarea {
			padding: 26px 10px 10px;
		}

		/**
		 *	Submit Button
		 */

		.gform_footer {
			//text-align:center;
		}

		/**
		 *	Validation Styles
		 */

		&.gform_validation_error {
			.gform_body {
				padding-left: 16px;
			}
		}

		div.validation_error {
			border: 0;
			background-color: $primary;
			color: #fff;
			font-weight: 500;
			padding: 10px 10px;
			display: block;
			margin-top: 30px;
			line-height: 1.5em;
			margin: 0 auto 7px;
			max-width: calc(100% - 32px);
			font-size: 16px;
		}

		&.gform_validation_error .top_label li.gfield.gfield_error {
			background-color: rgba($primary, 0.6);
			padding: 0px 10px;
			border-color: rgba($primary, 0.6);
			input {
				border: none;
				max-width: 95%;
			}
			.ginput_container {
				padding-right: 10px;
			}
			.validation_message {
				color: #fff;
				font-weight: normal;
				line-height: 1.5em;
				padding-top: 6px;
				padding-bottom: 5px;
				font-size: 14px;
				text-align: left;
			}
		}

		li.gfield_error
			input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
		li.gfield_error textarea {
			background-color: rgba(255, 255, 255, 0.8);
			border: none;
			border-bottom: 1px solid $primary;
			padding-left: 10px;
			padding-right: 10px;
		}

		&.gform_validation_error
			.gform_body
			ul
			li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
			border-bottom: 0;
		}

		button[type="submit"] {
			min-width: 134px;
			min-height: 34px;
			text-align: center;
		}

		.gchoice_select_all,
		ul.gfield_checkbox li input[type="checkbox"]:checked + label,
		ul.gfield_radio li input[type="radio"]:checked + label {
			font-weight: inherit;
		}

		/**
		 *  Checkbox customizations
		 */

		/* Customize the label (the container) */
		.gfield_checkbox {
			display: block;
			position: relative;
			padding-left: 0px;
			margin-bottom: 12px;
			cursor: pointer;
			user-select: none;

			/* Hide the browser's default checkbox */
			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;
			}
			label {
				padding-left: 29px;
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					height: 19px;
					width: 19px;
					background-color: transparent;
					border-radius: 2px;
					border: 1px solid #979797;
					transition: $transition;
				}

				&:after {
					content: "";
					position: absolute;
					display: none;
					left: 7px;
					top: 3px;
					width: 5px;
					height: 10px;
					border: solid white;
					border-width: 0 3px 3px 0;
					transform: rotate(45deg);
				}
			}

			/* On mouse-over, add a grey background color */
			&:hover input ~ label:before {
				//background-color: #979797;
			}

			/* When the checkbox is checked, add a blue background */
			input:checked ~ label:before {
				background-color: $primary;
				border-color: $primary;
			}

			/* Show the checkmark when checked */
			input:checked ~ label:after {
				display: block;
			}
		}

		.hide-label {
			label.gfield_label {
				display: none;
			}
		}

		.limit-description {
			max-width: 400px;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
