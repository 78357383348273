.block.quote {

	background-color: white;
	margin:0;
	padding:60px 0;

	blockquote {
		position: relative;
	    font-size: 20px;
	    line-height: 1.4em;
	    &:before {
	    	content:'';
	    	position:absolute;
	    	top:-30px;
	    	left:0;
	    	width:75px;
	    	height: 2px;
	    	background-color:$primary;
	    }
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		blockquote {
		    font-size: 24px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}