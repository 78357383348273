.basic-banner {

	background: {
		size:cover;
		position:center;
		repeat:no-repeat;
		color:$navy;
	}
	@include overlay($navy,0.6);
	
	padding-top: calc(25px + #{$header-mobile-height});
	padding-bottom:25px;

	.title {
		color:white;
		font-weight: 100;
	}

	&.center {
		text-align: center;
	}
	&.right {
		text-align: right;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding-top: calc(50px + #{$header-desktop-height});
		padding-bottom:50px;

		.title {
			font-size:53px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}