.page-template-home {
	.mobile-image {
		display: block;
		position:relative;
	}
	.background-image {	
		position:relative;
	    background-size: contain;
	    background-repeat: no-repeat;
	    background-position: top center;
	}
	.banner-content-container {
		background-color: $navy;
	}
	.banner-content {
		min-height: calc(100vh - (#{$header-mobile-height}) - #{$footer-mobile-height});

		.left {
			.content {
				margin-top:-20px;
				h1,h2 {
					color:#fff;
					font-weight: 500;
					letter-spacing:0.015em;
					line-height: 1.3em;
				}

				h1 {
					margin-top:0;
					margin-bottom:1em;
				}

				h2 {
					margin-bottom:20px;
				}

				.description {
					color: #fff;
					opacity: 0.85;
					font-size: 16px;
					letter-spacing: .075em;
					line-height: 2em;
				}
			}
		}
	}

	.btn-wrap {
		margin-top: 50px;
		.home-button {
			letter-spacing:3px;
			text-transform: uppercase;
			color:#fff;
			font-weight: 500;
			position: relative;
			display: inline-block;
			font-size: 11px;
			padding-right: 20px;
			.link-title {
				position: relative;
				z-index: 2;
			}
			.square {
				border-right:1px solid $primary;
				border-top:1px solid $primary;
				border-bottom:1px solid $primary;
				position: relative;
				height: 62px;
				width:62px;
				display: block;
				margin-left: -38px;
				position:absolute;
				z-index: 1;
				transition:background-color 0.3s ease, transform 0.3s ease;
				right: 0;
				top: 50%;
				transform:translate(56%, -50%);
				&:before,
				&:after {
					content:'';
					position:absolute;
					height: 25%;
					width:1px;
					background-color: $primary;
					left:0;
				}
				&:before {
					top:0;
				}
				&:after {
					bottom:0;
				}
				i {
					position:absolute;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					font-size:18px;
				}
			}
			&:hover, &:focus {
				outline:none;
				color:$primary;
				.square {
					background-color: $primary;
					transform:translate(75%,-50%);
					i {
						color:#fff;
					}
				}
			}		
		}
	}

	.right {
		height: 100%;
	}

	.home-banner-nav {
		@include list();
		margin-top:100px;
		margin-bottom:50px;

		.nav-item {
			border-top-width: 5px;
			border-right-width: 0;
			border-bottom-width: 0;
			border-left-width: 0;
			border-style: solid;
			margin-bottom:30px;
			@include bgImg(center, cover);
			@include overlay(black,0.3);
			padding:95px 20px 10px 20px;
			cursor: pointer;
			opacity: 0;
			transform:translateX(30%);
			transition: opacity 1s ease, transform 1.2s ease;
			&:before {
				transition:background-color 0.3s ease;
			}
			&:hover {
				@include overlay(black,0.1);
			}
			&:last-child {
				margin-bottom:0;
			}
			.nav-item-content {
				color:#fff;
				font-size: 21px;
				letter-spacing:0.05em;
				font-weight: 100;
				z-index: 1;
				position:relative;
				opacity: 0.9;
			}
			&.transition-0 {
				animation-delay: 0s;
				transition-delay: 0.15s;
			}
			&.transition-1 {
				animation-delay: 0.15s;
				transition-delay: 0.30s;
			}
			&.transition-2 {
				animation-delay: 0.30s;
				transition-delay: 0.45s;
			}
		}
	}

	.slick-current {
		.nav-item {
			transform:translateX(0);
			opacity: 1;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.mobile-image {
			display: none;
		}

		.background-image {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			position: absolute;
		    top: 0;
		    left:0;
		    width:100%;
		    height: 100%;
		}
		.banner-content-container {
			padding-top:calc(0px + #{$header-desktop-height});
		}
		.banner-content {
			min-height: calc(100vh - (#{$header-desktop-height}) - #{$footer-desktop-height});
			.left {
				.content {
					margin-top: 0;
					h1 {
						font-size:55px;
					}
				}
			}
		}
		

		.btn-wrap {
			.home-button {
				font-size: 13px;
			}
		}

		.home-banner-nav {
			margin-bottom: 0;
			.nav-item {
				border-top-width: 3px;
				padding:55px 20px 10px 20px;
			}
		}


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}