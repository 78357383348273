.site-header {
	position:absolute;
	z-index: 100;
	top: 0;
	width: 100%;

	.shrink & {
		position: fixed;
		animation-duration: 1s;
		animation-fill-mode: both;
		animation-name: fadeIn;
		background-color: black;
	}

	@media screen and (max-width:783px) {
		.admin-bar & {
			//top: 46px;
		}
	}

	.header-container {
		position:relative;
		z-index: 10;
	}
	
	.site-info {
		transition: $transition;
		padding:0;
		.shrink & {
			background-color: black;
		}
		.menu-open & {
			
		}
		.row.header-row {
			padding:0 15px;
			height: $header-mobile-height;
			.shrink & {
				height: $header-mobile-height - 40px;
			}
		}

		.brand {
			width:100%;
			max-width:125px;
			img {
				width:100%;
				display:block;
			}
		}



	}

	@include media-breakpoint-down(sm) {
		.tertiary-nav-row {
			&.open {
				.tertiary-row-selector-col {
					flex: 0 0 100%;
		    		max-width: 100%;
	    		}
	    		.tertiary-row-nav-col {
	    			display: none;
	    		}
			}
			.tertiary-row-nav-col {
				background-color: #e5ebec;
			}
		}
	}

	.header-tertiary-nav  {
		@include media-breakpoint-down(sm) {
			.container {
				width: 100%;
				max-width: none;
			}
		}
		z-index: -1;
		position: absolute;
		background-color: white;
		transition:transform 0.3s ease;
		transform:translate(-50%, -100%);
		left:50%;
		width:100%;
		border-bottom:5px solid $primary;
		.product-section-nav {
			padding:8px 0;
			text-align: right;
			li a {
				color:black;
				font-size:15px;
			}
		}
		.reveal-tertiary-nav & {
			transform:translate(-50%, 0);
		}
		body:not(.shrink) & {
			display: none;
		}
	} 



	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


		.header-tertiary-nav  {
			.product-section-nav {
				padding:15px 0;
			}
		}

		.site-info {

			.row.header-row {
				height: $header-desktop-height;
				.shrink & {
					height: $header-desktop-height  - 70px;;
				}
			}

			.brand {
				max-width: 153px;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}