.block.products-list {

	.product-list-item {
		margin:100px 0;
	}

	.intro-container {
		margin-bottom:40px;	
	}

	.title {
		font-weight: 500;
		margin-top:0;
		letter-spacing:0em;
	}
	.sub-title {
		position:relative;
		padding-left:10px;
		text-transform:uppercase;
		letter-spacing:0.125em;
		font-size:12px;
		color:$grey-dark;
		&:before {
			content:'';
			position:absolute;
			height: 100%;
			width:2px;
			background-color: $secondary;
			left:0;
			top:-1px;
		}
	}
	.description {
		font-weight: 400;
		border-bottom:1px solid rgba(0,0,0,0.1);
		margin-bottom:20px;
		padding-bottom:20px;
		font-size:16px;
		.max-width {
			max-width:600px;
		}
	}

	.no-products-found {
		padding:20px;
		background-color:rgba(0,0,0,0.05);
		h4 {
			margin:0;
			font-weight: 300;
			i {
				margin-right: 7px;
				vertical-align: -1px;
			}
		}
	}	

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}