.wrap {
	font-size: 15px;
	color: $pColor;
	font-family: $pFont;
	line-height: 1.67;
	font-weight: 300;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $hColor;
		line-height: 1em;
		font-weight: 500;
	}

	p {
		line-height: 1.7em;
		&:first-child {
			margin-top: 0;
		}
	}

	a {
		text-decoration: none;
		color:$primary;
		transition:$transition;
		&:hover {
			color:$secondary;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		h1 {
			font-size:50px;
			font-weight: 200;
		}
		h2 {
			font-size:40px;
			font-weight: 300;
		}
		h3 {
		}
		h4 {
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
