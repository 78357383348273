.contact {
	position:relative;
	margin-bottom:0;
	padding-top:8vw;


	.description {
		line-height: 31px;
	}

	.form-container {
		background-color: white;
		padding:30px 40px;
		margin-top:40px;
	}

	&:before {
		content:'';
		position: absolute;
		bottom:0;
		left:0;
		width:100%;
		height: 50%;
		background: {
			position:center;
			size:cover;
			repeat:no-repeat;
		}
	}

	.contact-details {
		padding:40px 0 75px;
		color:white;
		a {
			color:white;
			&:hover {
				color:$primary;
			}
		}
		.contact-details-title {
			color:white;
			font-weight: 100;
			font-size: 28px;
		}
		.content {
			opacity: 0.9;
			font-size: 12px;
			letter-spacing:2.8px;
			line-height: 23px;
			.address {
				margin-bottom:10px;
			}
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding-top:3vw;

		.form-container {
			margin-top:0px;
		}
		.contact-details {
			margin-top:-100px;
			padding-bottom:165px;
			padding-top: 0;
		}

		.gform_wrapper .gform_footer {
			text-align: right;
			margin-top: -55px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}