@import "common/variables";
@import "common/mixins";
@import "common/normalize";
@import "common/icons";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/slick-carousel/slick/slick.scss";
// endbower

@import "bootstrap/grid";

@import "common/global";
@import "common/typography";
@import "common/animations";

@import "components/buttons";
@import "components/desktop-menu";
@import "components/forms";
@import "components/menu-trigger";
@import "components/mobile-menu";
@import "components/wp-classes";
@import "components/product-section-nav-container";
@import "components/product";
@import "components/nav-page-selector";
@import "components/breadcrumbs";

@import "layouts/header";
@import "layouts/footer";
@import "layouts/tinymce";

@import "blocks/block";
@import "blocks/block-interior-banner";
@import "blocks/block-products-list";
@import "blocks/block-products-extra-links";
@import "blocks/block-contact";
@import "blocks/block-image-content";
@import "blocks/block-content";
@import "blocks/block-basic-content";
@import "blocks/block-basic-banner";
@import "blocks/block-quote";
@import "blocks/block-image";

@import "pages/error404";
@import "pages/home";

@import "../../bower_components/lity/dist/lity.css";

@import 'vendor/lity-overrides';
@import 'vendor/slick';