.product-container {
	.product {
		background-color:#fff;
		padding: 40px 40px 25px 40px;
		margin:10px 0px;
	
		.product-title {
			font-size:26px;
			margin:0 0 20px 0;
			position: relative;
			padding-right:70px;
			.product-title-inner {
				display: block;
			}
			.new-product {
			    background-color: $primary;
			    color: white;
			    font-size: 16px;
			    padding: 3px 10px;
			    vertical-align: -14px;
			    letter-spacing: 1px;
			}
		}

		.excerpt {
			.excerpt-container {
				ul,ol {
					margin:0;
					padding-left:17px;
					columns:1;
					li {
						padding-right:10px;
						margin-bottom:2px;
					}
				}
			}
		}
	
		.left-wrap {
			@extend .row;
			@include media-breakpoint-up(md) {
				height: 100%;
				flex-direction: column;
				justify-content: space-between;
			}
		}
		.image-wrap {
			width:100%;
			margin-bottom:20px;
			img {
				display: block;
			}		
		}
		.icon-wrap {
			margin:0 auto 20px;
			.icon {
				padding: 0 5px;
				text-align: center;
				margin-bottom: 5px;
			}
		}
		.content {
			display: none;
		}
	}


	.product-btns {
		margin-top:20px;
		text-align: left;
		.btn-wrap {
			display: inline-block;
			&:first-child {
				margin-right: 10px;
			}
		}
	}
	
	.content-container {
		ul,ol {
			margin:0;
			padding:0 0 0 17px;
			columns:1;
			li {
				padding-right:10px;
				margin-bottom:2px;
			}
		}
	}

	.more-info-toggle {
		margin-top:20px;
		display: inline-block;
		font-weight: bold;
		letter-spacing:0.250em;
		text-transform: uppercase;
		font-size:11px;
		&:before {
			content:'MORE';
		}
		i {
			transform: rotate(0deg);
			margin-left: 15px;
			vertical-align: -1px;
			font-size: 12px;
			transition:transform 0.3s ease;
		}
		&.open {
			i {
			    transform: rotate(45deg);
			    display: inline-block;
			}
			&:before {
				content:'LESS';
			}
		}
	}

	.guides {
		position:relative;
		margin-top:20px;
		padding-top:20px;
		&:before {
			content: '';
			position: absolute;
			background-color: rgba(0,0,0,0.1);
			width: calc(100% - 30px);
			top: 0;
			left: 50%;
			height: 1px;
			transform:translateX(-50%);
		}
		.btn-wrap {
			display: block;
			margin-bottom:5px;

		}
		a {
			color:$grey-med;
			letter-spacing:0.250em;
			text-transform: uppercase;
			font-weight: 500;
			font-size:11px;
			&:hover {
				color:$secondary;
			}
		}
	}

	.gated-download-form {
		position:fixed;
		height:100%;
		width:100%;
		left:0;
		top:0;
		background-color:rgba(0,0,0,0.75);
		z-index: 999;
		.gfield_visibility_hidden {
			display: none;
		}
		.gated-download-form--inner {
			position: absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			max-width:775px;
			width:100%;
		}
		.gated-download-form--content {
			background-color:white;
			padding:40px 50px;
			margin:20px;
			border-radius: 13px;
		}
		.gated-download-form--bg {
			position: relative;
			height: 100%;
			margin: 0 auto;
			overflow-y: auto;
			max-height: 70vh;
		}
		.gated_download_form--close {
			position: absolute;
			top: 30px;
			right:30px;
			height:40px;
			line-height: 40px;
			background-color:transparent;
			color:$pColor;
			font-size: 40px;
			width:40px;
			text-align: center;
			transition:$transition;
			cursor: pointer;
			border-radius: 50%;
			&:hover {
				color:$primary;
			}
		}
		.gfield.gfield_html  {
			h1,h2,h3,h4,h5,h6 {
				margin:0 0 10px 0;
			}
		}
		.gform_footer {
			text-align: right;
		}
		.gated-confirmation {
			display:block;
			text-align:center;
		}
		.gated-confirmation--link_wrap {
			display: block;
			text-align: center;
			border-top: 1px solid #e4e8e9;
			padding:30px 0;
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {



	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

		.guides {
			.btn-wrap {
				display: inline-block;
				margin-bottom: 0;
				&:first-child {
					margin-right: 10px;
					&:after {
						padding-left:8px;
						content:'|';
						color:$grey-med;
					}
				}
			}
		}

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.product {
			.product-title {
				position: relative;
				.product-title-inner {
					display: inline-block;
				}
				.new-product {
					position: absolute;
					right:0;
				    vertical-align: 2px;
				    top: 50%;
	    			transform: translateY(-50%);
				}
			}

			.excerpt {
				.excerpt-container {
					ul,ol {
						columns:2;
					}
				}
			}
			.icon-wrap {
				margin:0 auto;
				width:77%;
				max-width:165px;
				.icon {
					padding:0 5px;
				}
			}
		}


		.content-container {
			ul,ol {
				columns:2;
			}
		}

		
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		
		.product {
			.image-wrap {
				margin-bottom:0;
				text-align: center;
			}

			.product-btns {
				margin-top: 0;
				text-align: right;
				.btn-wrap {
					margin-top: 0;
					width:100%;
					.btn {
						letter-spacing: 1px;
						width:100%;
					}
					&:first-child {
						margin-right: 0;
					}
					&:last-child {
					}
				}
			}
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
		.product {
			.image-wrap {
				max-width:90%;
			}
		}

	}
}