.block {

	margin:60px 0;

	&.no-blocks {
		margin-top: 0;
		margin-bottom: 0;
		background-color: rgba(black,0.8);
		color:#fff;
		h1 {
			color:#fff;
		}
		.row {
			min-height:calc(100vh - 66px);
		}
	}


	// If the first block is a banner
	&[data-layout='0'][class*=banner] {
		margin-top:0;
		margin-bottom: 0;
		padding-top:calc(0px + #{$header-mobile-height});
		
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		&.no-blocks {
			.row {
				min-height:calc(100vh - 47px);
			}
		}

		&[data-layout='0'][class*=banner] {
			padding-top:calc(0px + #{$header-desktop-height});
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}