.block.interior-banner {
	position:relative;
	background: {
		position:center;
		size:cover;
		repeat:no-repeat;
	}
	padding-bottom:50px;

	.gallery-container,
	.gallery-container div:not(.slick-arrow) {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background: {
			position:center;
			size:cover;
			repeat:no-repeat;
		}
	}

	.slick-arrow {
	    display: block;
	    position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
	    color: white;
	    font-size: 37px;
	    opacity: 0.5;
	    transition:$transition;
	    cursor: pointer;
	    z-index: 1;
		&.slick-prev {
			left: 15px;
		}
		&.slick-next {
			right: 15px;
		}
		&:hover {
			opacity: 1;
		}
	}

	.content {
		padding:38vw 45px 50px;
	}

	.title {
		font-weight: 500;
		color:#fff;
		margin-top: 0;
	}

	.interior-banner-block-description {
		color:white;
		font-size:16px;
		letter-spacing:0.075em;
	}


	.tertiary-menu-container {
		display: none;
	}
	.delay-me {
		animation-delay:0.5s;
	}
	.animated {
		@for $i from 1 through 50 {
			&.delay-#{$i}s {
				animation-delay: calc(#{$i} * 0.1s + 0.1s);
			}
		}
	}

	.down-indicator {
		position: absolute;
		bottom:40px;
		left:50%;
		transform:translateX(-50%);
		color:white;
		font-size:30px;
		cursor: pointer;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		border-bottom:0;
		height:100%;
		min-height:100vh;

		.content {
			padding:0px 15px;
			text-align:left;
		}

		.tertiary-menu-container {
			display: block;
		}

		

		.inner-row {
			min-height: calc(100vh - (#{$header-desktop-height} * 2));
			height:100%;
		}

		.title {
			font-size:55px;
			margin-bottom:40px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}