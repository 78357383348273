.image-content {
	overflow: hidden;
	padding:8vw 0;
	margin:0;
	position: relative;
	z-index: 2;
	.title {
		margin-top: 0;
	}
	
	&.grey {
		background-color:$grey-light;
	}
	&.white {
		background-color:white;
	}
	&.grey-white {
		background: linear-gradient(to bottom, $grey-light 75%, white 75%, white 100% );
	}
	&.white-grey {
		&.custom {
			background:white;
			&:before {
				content:'';
				width:100%;
				height:8vw;
				position:absolute;
				bottom:0;
				width:100%;
				left:0;
				background-color:$grey-light;
			}
			&:after {
				content:'';
				position:absolute;
				bottom:8vw;
				left:50%;
				transform: translate(85%,50%);
				width:350px;
				height:77px;
				background: {
					image: url('../images/dots.svg');
					position:center;
					size:contain;
				}
			}
			.content-container {
				padding-bottom:30px;
				&:after {
					display:none;
				}
			}
		}
		background: linear-gradient(to bottom, white 75%, $grey-light 75%, $grey-light 100% );
	}

	.description {
		line-height: 31px;
	}

	&.custom {
		
		.custom-image-wrap {
			
			img {
				
			}
		}
		.content-container {
			margin-top:15vw;
		}
	}
	&:not(.simple) {
		.desktop-figure {
			display: none;
		}
	}
	.img-wrap {
		&.has-mobile-image {
			text-align: center;
			.desktop-image {
				display: none;
			}
		}
		
		&.left-margin-auto {
			text-align: right;
		}
		.mobile-figure {
			margin:0;
			display: inline-block;
			&.has-caption {
				margin-bottom: 20px;
				.mobile-image {
					margin-bottom:10px;
				}
			}
		}
		.desktop-figure {
			margin:0;
		}
		.mobile-caption {
			display: block;
			text-align: left;
		}
		.desktop-caption {
			display: none;
			text-align: left;
		}

		.mobile-image {
			margin:40px 0;
			display: block;
		}
	}

	&.image-background {
		background: {
			size:cover;
			position: center;
		}
		
		&.align-to-bottom {
			padding:9vw 0 9vw;
		}
		.content-container {
			padding:100px 35px 55px 35px;
			background-color: white;
			position: relative;
			&:before {
				content:'';
				position: absolute;
				top:60px;
				left:35px;
				height: 2px;
				width:75px;
				background-color: $primary;
			}
		}
	}

	&.full-width-image-with-overlay {
		padding:0 0 8vw 0;
		.content-container {
			padding:8vw 40px;
			.title,
			.description {
				position: relative;
				z-index: 2;
			}
			.title:before {
				top: -25px;
				left:0;
			}
			&:after {
				content:'';
				position: absolute;
				right:0;
				top:0;
				background-color: rgba(240,240,240,0.9);
				width: 100%;
				height: 100%;
			}
			&:before {
				position: relative;
				content:'';
				padding-bottom:56.25%;
				width:100%;
				z-index: 2;
				display: block;
				margin:0 auto 70px;
				background: {
					size:cover;
					position: center;
				}
			}
		}
	}
	&.full-width-image {
		&.white {
			border-top:50px solid white;
			border-bottom:50px solid white;
		}
		&.grey {
			border-top:50px solid $grey-light;
			border-top:50px solid $grey-light;
		}
		&:before {
			content:'';
			padding-bottom:56.25%;
			width:calc(100% - 30px);
			display: block;
			margin:0 auto 70px;
			background: {
				size:cover;
				position: center;
			}
		}
	}

	&.simple {
		.img-wrap {
			margin-bottom:70px;
		}
	}

	&.simple,
	&.full-width-image,
	&.custom {
		&.image-right {
			.content-container {
				&:after {
					left:-75px;
				}
			}
		}
		&.image-left {
			.content-container {
				&:after {
					right:-75px;
				}
			}
		}
		.content-container {
			position: relative;
			&:after {
				display: none;
				content:'';
				position:absolute;
				bottom:-3vw;
				transform: translateY(100%);
				width:350px;
				height:77px;
				background: {
					image: url('../images/dots.svg');
					position:center;
					size:contain;
				}

			}
		}
	}

	&.simple,
	&.full-width-image,
	&.full-width-image-with-overlay,
	&.custom {
		.content-container {
			position: relative;
			.title:before {
				content:'';
				position: absolute;
				top: -30px;
    			left: 0px;
				height: 2px;
				width:75px;
				background-color: $primary;
			}
		}	
	}

	.mobile-figure.image-background-figure {
		margin:0;
		display: block;
		img {
			display: block;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		
		&.full-width-image-with-overlay {
			.title:before {
			    top: -50px;
			}
			.content-container {
				position: relative;
			}
			&.image-right {
				.content-container {
					&:before {
						left:0
					}
				}
			}
			&.image-left {
				.content-container {
					&:before {
						right:0
					}
				}
			}
			.content-container {
				&:before {
					z-index: auto;
					margin-bottom: 0;
					padding-bottom: 0;
					content:'';
					width:100vw;
					position: absolute;
					top: 0;
					height: 100%;
					background: {
						size:cover;
						position:center;
					}
				}
			}
		}

		&.full-width-image {
			&.image-right {
				&:before {
					right:0;
				}
			}
			&.image-left {
				&:before {
					left:0;
				}
			}
			&:before {
				margin-bottom: 0;
				padding-bottom: 0;
				content:'';
				width:50%;
				position: absolute;
				top: 0;
				height: 100%;
				background: {
					size:cover;
					position:center;
				}
			}
		}
		&.simple.enlarge-image-on-desktop {
			&.image-left {
				.img-wrap {
					img {
						margin-left: -20%;
					}
				}
			}
			.content-container {
				&:after {
					//bottom: -60px;
				}
			}
			.img-wrap {
				margin-bottom:0px;
				img {
					max-width:120%;	
					width:120%;	
				}
			}
		}

		&.image-background {
			&.align-to-bottom {
				padding:32vw 0 6vw;
			}
			.content-container {
				padding:100px 75px 55px 75px;
				&:before {
					left:75px;
				}
			}
		}

		&.custom {
			padding:12vw 0 10vw;
			.custom-image-wrap {
				position: absolute;
				top: 4vw;
				left:0;
				width:100%;
				height: 100%;
				img {
					position: absolute;
					top: 0;
					left: 40%;
					max-width: 100%;
					transform: translateX(-39%);
				}
			}
			.content-container {
				margin-top:260px;
			}
		}

		&.simple,
		&.full-width-image,
		&.custom {
			.content-container {
				&:after {
					display: block;
				}
			}
		}

		&.simple,
		&.custom,
		&.full-width-image {
			.content-container {
				.title:before {
				    left: 20px;
				}
			}	
		}

		.img-wrap {
				&.has-mobile-image {
				.desktop-image {
					display: block;
				}
			}
			.desktop-figure {
				display: inline-block;
			}
			.mobile-figure {
				display: none;
			}
			.mobile-caption {
				display: none;
			}
			.desktop-caption {
				display: block;
			}
			.mobile-image {
				display: none;
			}
		}

		.content-container {
			padding-left: 20px;
		}	

		.mobile-figure.image-background-figure {
			
			display: none;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		&.simple,
		&.custom,
		&.full-width-image {
			.content-container {
				.title:before {
				    top: -50px;
				}
			}	
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		&.full-width-image-with-overlay {
			.content-container {
				.title,
				.description {
					max-width:375px;
					margin-left:auto;
					margin-right: auto;
				}
			}
		}


	
	}

	
	@media screen and (min-width:1600px) {
		&.full-width-image-with-overlay {
			.content-container {
				&:before {
					width:86vw;
				}
			}
		}
	}

}