.block.content {

	padding:8vw 0;
	margin:0;
	background-color:#fff;

	.title {
		max-width: 400px;
		margin:0 0 40px;
	}

	.btn {
		padding-left: 0;
	}

	.desktop-btn {
		display: none;
	}
	.mobile-btn {
		margin-top: 25px;
	}

	.content-container {
		
		line-height: 31px;

		.intro-text {
			font-size: 20px;
			color:$primary;
		}

		.content {

		}

	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.content-container {
			margin-left: 20px;
		}

		.desktop-btn {
			display: inline-block;
		}
		.mobile-btn {
			display: none;
		}


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
		&.negative-top-margin {
			margin-top:-12vw;
		}

	}
}