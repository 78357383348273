.wrap a.menu-toggle {
	display: inline-block;
	padding:5px 35px 5px 15px;
	color:#fff;
	cursor:pointer;
	font-size: 13px;
	font-weight: 400;
	letter-spacing:3px;
	position:relative;
	transition:background-color 0.3s ease;
	border:1px solid transparent;
	.bar {
		width:15px;
		height:2px;
		background-color:white;
		display: block;
		position: absolute;
		right: 13px;
		&.bar-1 {
			top:35%;
			transition:$transition;
		}
		&.bar-2 {
			bottom:35%;
			transition:$transition;
		}
	}
	&:hover {
		background-color: $primary;
		border:1px solid $primary;
		.bar {
			&.bar-1 {
				top:57%;
			}
			&.bar-2 {
				bottom:57%;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		border:1px solid white;



	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.shrink & {
			transform:translateY(22px);
		}
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}

}