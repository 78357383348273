// Colours
$error: #d52d2d;

$blue: #50bde3;
$yellow: #ffb423;
$navy: #001c25;

$grey-dark: #666666;
$grey-med: #99a0a2;
$grey-light: #f2f6f7;

$divider: #e3e8e9;

$primary: $blue;
$secondary: $yellow;

$pColor: black;
$hColor: black;

// Fonts
$azo: "azo-sans-web", sans-serif;
$pFont: $azo;
$hFont: $azo;

// Other
$transition: all 0.3s ease;

// Header sizes
$header-mobile-height: 100px;
$header-desktop-height: 150px;

// Footer sizes
$footer-mobile-height: 62px;
$footer-desktop-height: 42px;